import React from 'react';
import './rotg-view.css';
import dictionaryFile from '../../res/dictionary.txt';
export default class RotGView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      spellName: "",
      lastRunSpellName: "",
      dictionary: [],
      newSpells: [],
      hasResults: false,
      replaceEnabled: true,
      addEnabled: true,
      removeEnabled: true
    }

    this.loadDictionary();
  }

  updateSpellName = event => {
    this.setState({spellName: event.target.value.toLocaleLowerCase().trim()});
  }

  updateSpellList = () => {
    this.setState({newSpells: this.generateSpells()});
  }

  loadDictionary = () => {
    fetch(dictionaryFile)
      .then(r => r.text())
      .then(text => {
        this.state.dictionary = text.split('\n');
        this.state.dictionary = this.state.dictionary.map(s => s.trim());
        console.log(this.state.dictionary.length + " words loaded");
      });
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.updateSpellList();
    }
  }

  toggleReplace = (e) => {
    this.state.replaceEnabled = !this.state.replaceEnabled;
    if (this.state.hasResults) this.updateSpellList();
  }

  toggleAdd = (e) => {
    this.state.addEnabled = !this.state.addEnabled;
    if (this.state.hasResults) this.updateSpellList();
  }

  toggleRemove = (e) => {
    this.state.removeEnabled = !this.state.removeEnabled;
    if (this.state.hasResults) this.updateSpellList();
  }

  render() {
    var newSpells = [];
    for (var i = 0; i < this.state.newSpells.length; i++) {
      newSpells.push(
        <p id={i} className="rotg-p-newSpell">{this.state.newSpells[i]}</p>
      );
    }

    var statsClassName = "rotg-div-stats" + (this.state.hasResults ? "" : " hide");

    return (
      <div className="main">
        <div className="rotg-div-main">
          <h1 className="rotg-h1-title">Ring of the Grammarian</h1>
          <div className="rotg-div-spellInput">
            <input className="rotg-input-spellName" placeholder="Enter spell name" onChange={this.updateSpellName} onKeyDown={this.handleKeyDown}></input>
          </div>
          <div className="rotg-div-options">
            <div className="rotg-div-option">
              <input type="checkbox" onChange={this.toggleReplace} checked={this.state.replaceEnabled} />
              <p>Replace Letter</p>
            </div>
            <div className="rotg-div-option">
              <input type="checkbox" onChange={this.toggleAdd} checked={this.state.addEnabled} />
              <p>Add Letter</p>
            </div>
            <div className="rotg-div-option">
              <input type="checkbox" onChange={this.toggleRemove} checked={this.state.removeEnabled} />
              <p>Remove Letter</p>
            </div>
          </div>
          <div className="rotg-div-goButton">
            <button className="rotg-button-go" onClick={this.updateSpellList}>Go!</button>
          </div>
          <div className={statsClassName}>
            <p>Spell: {this.state.lastRunSpellName}</p>
            <p>Result Count: {this.state.newSpells.length}</p>
          </div>
          <div className="rotg-div-newSpells">
            {newSpells}
          </div>
        </div>
      </div>
    );
  }

  generateSpells = () => {
    var results = [];
    if (this.state.replaceEnabled) results = results.concat(this.generateSpellsReplaceLetter());
    if (this.state.addEnabled) results = results.concat(this.generateSpellsAddLetter());
    if (this.state.removeEnabled) results = results.concat(this.generateSpellsRemoveLetter());

    this.setState({hasResults: true, lastRunSpellName: this.state.spellName});
    return results;
  }

  generateSpellsReplaceLetter = () => {
    var results = [];
    var spellParts = this.state.spellName.split(' ');
    for (var j = 0; j < spellParts.length; j++) {
      var part = spellParts[j];
      for (var i = 0; i < part.length; i++) {
        for (var letterIndex = 0; letterIndex <= 26; letterIndex++) {
          var letter = String.fromCharCode(97 + letterIndex);
          var tempPart = part;
          var replaceLetter = tempPart[i];

          // Capitalize the letter if need be
          if (replaceLetter === replaceLetter.toUpperCase()) {
            letter = letter.toUpperCase();
          }

          // Skip if they are the same
          if (replaceLetter === letter) continue;

          tempPart = tempPart.replaceAt(i, letter);
          if (this.state.dictionary.includes(tempPart.toLocaleLowerCase())) {
            var newSpell = "";
            for (var k = 0; k < spellParts.length; k++) {
              if (k !== 0) newSpell += " ";
              newSpell += k === j ? tempPart : spellParts[k];
            }

            results.push(newSpell);
          }
        }
      }
    }
    return results;
  }

  generateSpellsAddLetter = () => {
    var results = [];
    var spellParts = this.state.spellName.split(' ');
    for (var j = 0; j < spellParts.length; j++) {
      var part = spellParts[j];
      for (var i = 0; i <= part.length; i++) {
        for (var letterIndex = 0; letterIndex <= 26; letterIndex++) {
          var letter = String.fromCharCode(97 + letterIndex);
          var tempPart = part.slice(0, i) + letter + part.slice(i);
          if (this.state.dictionary.includes(tempPart.toLocaleLowerCase())) {
            var newSpell = "";
            for (var k = 0; k < spellParts.length; k++) {
              if (k !== 0) newSpell += " ";
              newSpell += k === j ? tempPart : spellParts[k];
            }

            results.push(newSpell);
          }
        }
      }
    }
    return results;
  }

  generateSpellsRemoveLetter = () => {
    var results = [];
    var spellParts = this.state.spellName.split(' ');
    for (var j = 0; j < spellParts.length; j++) {
      var part = spellParts[j];
      for (var i = 0; i < part.length; i++) {
        var tempPart = part.slice(0, i) + part.slice(i+1);
        console.log(tempPart.toLocaleLowerCase());
        console.log(this.state.dictionary);
        if (this.state.dictionary.includes(tempPart.toLocaleLowerCase())) {
          var newSpell = "";
          for (var k = 0; k < spellParts.length; k++) {
            if (k !== 0) newSpell += " ";
            newSpell += k === j ? tempPart : spellParts[k];
          }

          results.push(newSpell);
        }
      }
    }
    return results;
  }
}

String.prototype.replaceAt = function (index, char) {
  let a = this.split("");
  a[index] = char;
  return a.join("");
}
