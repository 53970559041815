import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../navbar/navbar'; // Import the Navbar component
import './battle-map-view.css';

export default function BattleMapView() {
  const canvasRef = useRef(null);
  const [xOffset, setXOffset] = useState(0);
  const [yOffset, setYOffset] = useState(0);
  const [gridSize, setGridSize] = useState(50);
  const [dragging, setDragging] = useState(false);
  const [mouseStartX, setMouseStartX] = useState(0);
  const [mouseStartY, setMouseStartY] = useState(0);
  const [dragStartXOffset, setDragStartXOffset] = useState(0);
  const [dragStartYOffset, setDragStartYOffset] = useState(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const drawGrid = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);

      // Draw white background
      context.fillStyle = '#FFFFFF';
      context.fillRect(0, 0, canvas.width, canvas.height);

      // Draw grid lines
      context.strokeStyle = '#CCCCCC';
      context.lineWidth = 1;
      const startX = xOffset % gridSize;
      const startY = yOffset % gridSize;

      for (let x = startX; x < canvas.width; x += gridSize) {
        context.beginPath();
        context.moveTo(x, 0);
        context.lineTo(x, canvas.height);
        context.stroke();
      }

      for (let y = startY; y < canvas.height; y += gridSize) {
        context.beginPath();
        context.moveTo(0, y);
        context.lineTo(canvas.width, y);
        context.stroke();
      }
    };

    drawGrid();
}, [xOffset, yOffset, gridSize]);

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (e.button === 2) { // Right mouse button
        setDragging(true);
        setMouseStartX(e.clientX);
        setMouseStartY(e.clientY);
        setDragStartXOffset(xOffset);
        setDragStartYOffset(yOffset);
      }
    };

    const handleMouseUp = () => {
      setDragging(false);
    };

    const handleMouseMove = (e) => {
      if (dragging) {
        const newXOffset = e.clientX - mouseStartX + dragStartXOffset;
        const newYOffset = e.clientY - mouseStartY + dragStartYOffset;
        setXOffset(newXOffset);
        setYOffset(newYOffset);
      }
    };

    const handleWheel = (e) => {
      if (e.buttons === 2) { // Right mouse button
        e.preventDefault(); // Prevent page scrolling while right-clicking

        const scrollDelta = e.deltaY;
        const gridSizeChange = scrollDelta > 0 ? -10 : 10; // Decrease or increase grid size
        setGridSize((prevGridSize) => {
            var newGridSize = Math.max(prevGridSize + gridSizeChange, 10);
            var newGridSize = Math.min(newGridSize, 1000);
            return newGridSize;
        });
      }
    };

    const handleContextMenu = (e) => {
      e.preventDefault(); // Prevent the default context menu from showing up
    };

    const canvas = canvasRef.current;

    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("wheel", handleWheel);
    canvas.addEventListener('contextmenu', handleContextMenu);

    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mouseup", handleMouseUp);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("wheel", handleWheel);
      canvas.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [dragging, mouseStartX, mouseStartY, dragStartXOffset, dragStartYOffset, xOffset, yOffset]);

  return (
    <div className="battleMapView-container">
      <Navbar />
      <canvas
        ref={canvasRef}
        width={window.innerWidth} // Set the desired width of the canvas
        height={window.innerHeight} // Set the desired height of the canvas
        className="battleMapView-canvas"
      ></canvas>
    </div>
  );
}
