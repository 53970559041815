import './home-view.css';

export default function HomeView() {
    return (
        <div className="main homeView-div-main">
            <h1>Welcome Home!</h1>
            <p>Click the Navbar to get started</p>
        </div>
    );
}
