import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeView from "./views/home/home-view.js";
import BattleMap from "./views/battle-map/battle-map-view.js";
import RotGView from "./views/rotg/rotg-view.js";
import MapsView from "./views/maps/maps-view.js";
import PageNotFoundView from "./views/page-not-found/page-not-found-view.js";
import Navbar from './views/navbar/navbar.js';
export default function App() {

  return (
    <div>
      <Router>
          <Navbar />
          <Routes>
              <Route path="/" element={<HomeView />} />
              <Route path="/battlemap" element={<BattleMap />} />
              <Route path="/grammarian" element={<RotGView />} />
              <Route path="/maps" element={<MapsView />} />
              <Route path="*" element={<PageNotFoundView />} />
          </Routes>
      </Router>
    </div>
  );
}
