import React, { useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import styled from "styled-components";
import "./navbar.css"

const Nav = styled.nav`
    padding: 0 20px;
    height: 6rem;
    background: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 999999;

    @media (max-width: 720px) {
        height: 4rem;
    }
`;

// padding-right = 15 * number of nav menus
const Menu = styled.ul`
    width: 100%;
    display: flex;
    text-align: center;
    padding-right: 190px;

    li:nth-child(even) {
        margin: 0px 15px;
    }

    @media (max-width: 720px) {
        display: none;
    }
`;

const Item = styled.li``;

const NavIcon = styled.button`
    background: none;
    cursor: pointer;
    border: none;
    outline: none;

    @media (min-width: 720px) {
        display: none;
    }
`;

const Line = styled.span`
    display: block;
    border-radius: 50px;
    width: 25px;
    height: 3px;
    margin: 5px;
    background-color: #fff;
    transition: width 0.4s ease-in-out;

    :nth-child(even) {
        width: ${(props) => (props.open ? "40%" : "70%")};
    }
`;

const Overlay = styled.div`
    position: fixed;
    height: ${(props) => (props.open ? "100vh" : 0)};
    width: 100%;
    background: #000000;
    transition: height 0.4s ease-in-out;
    z-index: 999998;

    @media (min-width: 970px) {
        display: none;
    }
`;

const OverlayMenu = styled.ul`
    list-style: none;
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);

    li {
        opacity: ${(props) => (props.open ? 1 : 0)};
        font-size: 25px;
        margin: 50px 0px;
        transition: opacity 0.4s ease-in-out;
    }

    li:nth-child(even) {
        margin: 50px 0px;
    }
`;

const Header = () => {

    const [toggle, toggleNav] = useState(false);
    const closeMobileMenu = () => toggleNav(false);

    return (
        <>
            <Nav>
                <Menu>
                    <CustomLink to="/" className="navlink" >Home</CustomLink>
                    <CustomLink to="/battlemap" className="navlink" >Battle Map</CustomLink>
                    <CustomLink to="/grammarian" className="navlink" >Grammarian</CustomLink>
                    <CustomLink to="/maps" className="navlink" >Maps</CustomLink>
                </Menu>
                <NavIcon onClick={() => toggleNav(!toggle)}>
                    <Line open={toggle} />
                    <Line open={toggle} />
                    <Line open={toggle} />
                    <Line open={toggle} />
                </NavIcon>
            </Nav>

            <Overlay open={toggle}>
                <OverlayMenu open={toggle}>
                    {toggle && <Item><CustomLink to="/" className="navlink" onClick={closeMobileMenu}>HOME</CustomLink></Item>}
                    {toggle && <Item><CustomLink to="/battlemap" className="navlink" onClick={closeMobileMenu}>BATTLE MAP</CustomLink></Item>}
                    {toggle && <Item><CustomLink to="/grammarian" className="navlink" onClick={closeMobileMenu}>GRAMMARIAN</CustomLink></Item>}
                    {toggle && <Item><CustomLink to="/maps" className="navlink" onClick={closeMobileMenu}>MAPS</CustomLink></Item>}
                </OverlayMenu>
            </Overlay>

        </>
    );
};

export default Header;

function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })

    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>
                {children}
            </Link>
        </li>
    )
}
