import './page-not-found-view.css';

export default function PageNotFoundView() {
    return (
        <div className="main">
            <h1>Not found!</h1>
            <p>Sorry your page was not found!</p>
        </div>
    );
}
