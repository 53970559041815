import './maps-view.css';

export default function MapsView() {
    return (
        <div className="main mapsView-div-main">
            <h1>Maps</h1>
            <p>Coming soon!</p>
        </div>
    );
}
